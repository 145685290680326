import BigNumber from 'bignumber.js';

import { ZERO } from 'lib/consts';

import { toFixedString } from './formatNumber';

interface Params {
  value: string;
  exchangeRate?: string | null;
  accuracy?: number;
  accuracyUsd?: number;
  decimals?: string | null;
  fixed?: number;
}

export default function getCurrencyValue({ value, accuracy, accuracyUsd, decimals, exchangeRate, fixed = 18 }: Params) {
  const valueCurr = BigNumber(value).div(BigNumber(10 ** Number(decimals || '18')));
  // const valueResult = toFixedString(accuracy ? valueCurr.dp(accuracy) : valueCurr, fixed);
  const valueResult = accuracy ? valueCurr.dp(accuracy).toFormat() : valueCurr.toFormat();

  let usdResult: string | undefined;
  let usdBn = ZERO;

  if (exchangeRate) {
    const exchangeRateBn = new BigNumber(exchangeRate);
    usdBn = valueCurr.times(exchangeRateBn);
    if (accuracyUsd && !usdBn.isEqualTo(0)) {
      const usdBnDp = usdBn.dp(accuracyUsd);
      usdResult = toFixedString(usdBnDp.isEqualTo(0) ? usdBn.precision(accuracyUsd) : usdBnDp, fixed);
    } else {
      usdResult = toFixedString(usdBn, fixed);
    }
  }
  return { valueStr: valueResult, usd: usdResult, usdBn };
}
