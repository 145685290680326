import BigNumber from 'bignumber.js';

BigNumber.config({ EXPONENTIAL_AT: [ -8, 8 ] });

export function formatNumber(num: number, precision = 2) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
}

export const truncateString = (str: string, maxLength = 10) => {
  if (!str) {
    return str;
  }

  const data = str.split('.');
  if (data[0].length > maxLength) {
    return `${ str.substring(0, maxLength) }e+${ data[0].length - maxLength }`;
  } else {
    return str.length > maxLength ? `${ str.substring(0, maxLength) }` : str;
  }
};

export const toFixedString = (str: BigNumber | undefined, maxLength = 2) => {
  if (!str || (str?.toString() === '0')) {
    return str?.toString();
  }

  const data = str.toString().split('.');
  const match = str.toString().match(/e\+\d*$/g);

  if (data[1]?.length > maxLength) {
    return `${
      new BigNumber(data[0]).toFormat()
    }.${
      data[1].substring(0, maxLength) || 0
    }${
      match?.[0] || ''
    }`;
  } else {
    return str?.toFormat(maxLength).replace(/\.?0+$/, '');
  }
};
